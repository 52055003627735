import React from 'react';
import '../styles/manager.css';
import SignupForm from '../components/SignupForm';


const Lila = () => {
 

  return (
    <div className="cont">
      <main className="main-content">
        <div className="welcome-container">
          <div className="welcome-content">
            <div className="header-section">
              <div className="logo-container">
                <span className="logo">M</span>
              </div>
              <h1 className="welcome-title">Welcome to Your Personal Manager</h1>
              <p className="welcome-subtitle">Your Success Journey Begins Here</p>
            </div>

            <div className="manager-section">
              <div className="manager-card">
                <div className="manager-avatar">
                  <span>👨‍💼</span>
                </div>
                <div className="manager-info">
                  <h2>Your Dedicated Manager</h2>
                  <p className="manager-id">Lila561 </p>
                  <div className="contact-buttons">
                    <a 
                      href="https://t.me/Promoter11234" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="telegram-btn"
                    >
                      <svg className="telegram-icon" viewBox="0 0 24 24">
                        <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.562 8.161c-.18 1.897-.962 6.502-1.359 8.627-.168.9-.5 1.201-.82 1.23-.697.064-1.226-.461-1.901-.903-1.056-.692-1.653-1.123-2.678-1.799-1.185-.781-.417-1.21.258-1.911.177-.184 3.247-2.977 3.307-3.23.007-.032.014-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.139-5.062 3.345-.479.329-.913.489-1.302.481-.428-.008-1.252-.241-1.865-.44-.754-.244-1.349-.374-1.297-.789.027-.216.325-.437.893-.663 3.498-1.524 5.831-2.529 6.998-3.014 3.332-1.386 4.025-1.627 4.476-1.635.099-.002.32.023.463.178.119.13.154.307.164.387.015.12.027.347.016.539z"/>
                      </svg>
                      Contact on Telegram
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="features-section">
              <div className="feature">
                <div className="feature-icon">💼</div>
                <h3>Professional Support</h3>
                <p>Get personalized guidance for your investment journey</p>
              </div>
              <div className="feature">
                <div className="feature-icon">📊</div>
                <h3>Market Analysis</h3>
                <p>Access to detailed market insights and trends</p>
              </div>
              <div className="feature">
                <div className="feature-icon">🎯</div>
                <h3>Strategic Planning</h3>
                <p>Custom strategies aligned with your goals</p>
              </div>
            </div>
          </div>
        </div>

        <SignupForm />

        <div className="bitcoin-animation">
          <div className="circle-container">
            <div className="circle circle-outer"></div>
            <div className="circle circle-inner"></div>
            <div className="bitcoin-logo">₿</div>
            <div className="dot dot1"></div>
            <div className="dot dot2"></div>
            <div className="dot dot3"></div>
          </div>
          <div className="circuit-lines">
            <div className="line line1"></div>
            <div className="line line2"></div>
            <div className="line line3"></div>
            <div className="line line4"></div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Lila;
