import React from 'react';
import '../styles/PyramidInvestment.css'; // Add a CSS file to style the component

const PyramidInvestment = () => {
  return (
    <div className="pyramid-investment">
      <h2>Investment Pyramid Structure</h2>
      <p>
        Our company uses a three-level investment structure, where you can earn commissions from your direct and indirect referrals.
        Each level gives you a percentage of the profits generated by the referred users.
      </p>
      <div className="levels-container">
        <div className="level">
          <h3>Level 1</h3>
          <p>Earn <strong>10%</strong> from direct referrals' profits.</p>
        </div>
        <div className="level">
          <h3>Level 2</h3>
          <p>Earn <strong>7%</strong> from indirect referrals (people referred by your direct referrals).</p>
        </div>
        <div className="level">
          <h3>Level 3</h3>
          <p>Earn <strong>3%</strong> from second-level indirect referrals (people referred by your Level 2).</p>
        </div>
      </div>
    </div>
  );
};

export default PyramidInvestment;
