import React from 'react';
import { useSelector } from 'react-redux';
import '../styles/investmentPlans.css';

const InvestmentPlans = () => {
  // Use Redux to retrieve investment plans from the store
  const plans = useSelector((state) => state.investmentPlans.plans);

  return (
    <section className="investment-plans">
      <h3>Investment Plans</h3>
      <div className="plans-container">
        {plans.map((plan, index) => (
          <div key={index} className="plan">
            <h4>{plan.vipLevel}</h4>
            <p>Investment Amount: {plan.amountRange} $</p>
            <p>Profit Percentage: {plan.profitPercentage}</p>
            <p>Valid Direct Members: {plan.directMembers}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default InvestmentPlans;
