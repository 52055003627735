import React, { useState } from 'react';
import Roulette from '../assests/13459.png'; // The roulette icon image
import Modal from './Modal'; // Import the modal component
import LuckySpin from './LuckySpin'; // Import the LuckySpin component
import '../styles/roulette.css'; // Import the CSS for the icon

const RouletteIcon = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div>
      <div className="Roulette-icon-container">
        <img
          src={Roulette}
          alt="Roulette"
          className="Roulette-icon"
          onClick={openModal} // Trigger modal open on icon click
        />
      </div>

      {/* Modal containing the LuckySpin component */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <LuckySpin /> {/* LuckySpin component inside the modal */}
      </Modal>
    </div>
  );
};

export default RouletteIcon;