import React, { useEffect } from 'react';
import '../styles/CryptoPricesNews.css';

const CryptoPricesNews = () => {
  useEffect(() => {
    // Create a <script> tag and add it to the DOM to load the CoinGecko widget
    const script = document.createElement('script');
    script.src = "https://widgets.coingecko.com/gecko-coin-list-widget.js";
    script.async = true;
    document.body.appendChild(script); // Add the script to the body of the document

    return () => {
      // Cleanup when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="crypto-prices-news">
      <h2>Currency prices</h2>
      {/* Container for the widget */}
      <div>
        <gecko-coin-list-widget
          locale="en"
          dark-mode="true"
          outlined="true"
          coin-ids="tron,bitcoin,solana,ethereum"
          initial-currency="usd"
          width="390"
        ></gecko-coin-list-widget>
      </div>
    </div>
  );
};

export default CryptoPricesNews;
