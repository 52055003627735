import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/LuckySpin.css';

const LuckySpin = () => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [rotationDegree, setRotationDegree] = useState(0);
  const [result, setResult] = useState(null);
  const [spinInfo, setSpinInfo] = useState(null);
  const [error, setError] = useState(null);
  const [winAmount, setWinAmount] = useState(null); // New state for the won amount

  const segments = [
    '0.1', '0.2', '0.3', '0.5', '1', '1.2', '1.5', '2', '2.5', '5',
    '10', '20', '50', '70', '100', '150', '200', '500', '800', '1000'
  ];

  useEffect(() => {
    checkSpinAvailability();
  }, []);

  const checkSpinAvailability = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user')); // Récupérer l'objet utilisateur depuis le localStorage
      const userId = user?.id; // Récupérer l'ID de l'utilisateur
      const token = localStorage.getItem('token'); // Récupérer le token depuis le localStorage
      
      // Mise à jour de l'URL pour inclure l'ID utilisateur
      const response = await axios.get(`https://back1-f8jo.onrender.com/api/auth/lucky-spin/check/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setSpinInfo(response.data);
    } catch (err) {
      setError(err.response?.data?.error || 'Error checking spin availability');
    }
  };

  const handleSpin = async () => {
    if (isSpinning || !spinInfo?.canSpin) return;

    setIsSpinning(true);
    setError(null);

    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const userId = user?.id; // Get user ID
      const token = localStorage.getItem('token');

      // Corrected URL string interpolation
      const response = await axios.post(`https://back1-f8jo.onrender.com/api/auth/lucky-spin/spin/${userId}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'User-ID': userId // Include User-ID header
        }
      });

      const { winAmount: amountWon, message } = response.data;

      // Find the index of the segment corresponding to the won amount
      const winningIndex = segments.findIndex(segment => parseFloat(segment) === parseFloat(amountWon));

      // Calculate the rotation
      const baseRotation = 360 / segments.length;
      const targetRotation = winningIndex * baseRotation;
      const offset = baseRotation / 2;
      const totalRotation = 360 * 5 + targetRotation + offset;

      setRotationDegree(totalRotation);
      setWinAmount(amountWon); // Store the won amount

      setTimeout(() => {
        setResult(message);
        setIsSpinning(false);
        checkSpinAvailability();
      }, 3000);
    } catch (err) {
      setError(err.response?.data?.error || 'Error spinning the wheel');
      setIsSpinning(false);
    }
  };

  return (
    <div className="spin-container">
      <div className="container" style={{ transform: `rotate(${rotationDegree}deg)` }}>
        {segments.map((value, index) => (
          <div
            key={index}
            className={`segment segment-${index + 1}`}
            style={{
              transform: `rotate(${index * (360 / segments.length)}deg)`,
              backgroundColor: `hsl(${index * (360 / segments.length)}, 50%, 50%)`
            }}
          >
            {value}
          </div>
        ))}
      </div>
      <span className="mid"></span>
      <button
        id="spin"
        onClick={handleSpin}
        disabled={isSpinning || !spinInfo?.canSpin}
      >
        {isSpinning ? 'Spinning...' : 'Spin'}
      </button>

      {error && <div className="error">{error}</div>}
      {result && (
        <div className="result">
          <h2 style={{ color: 'green' }}>Congratulations!</h2>
          <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
            You have won <span style={{ color: 'gold' }}>{winAmount}</span>!
          </p>
        </div>
      )}
      {spinInfo && (
        <div className="spin-info">
          <p>Spins remaining today: {spinInfo.spinsRemaining}</p>
        </div>
      )}
    </div>
  );
};

export default LuckySpin;
