import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Modal from '../components/Modal';
import '../styles/Admin.css';
import LuckySpin from '../components/LuckySpin';

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [withdrawRequests, setWithdrawRequests] = useState([]);
  const [editingWithdrawRequest, setEditingWithdrawRequest] = useState(null);

  // Fetch Users and Withdrawal Requests
  useEffect(() => {
    fetchUsers();
    fetchWithdrawRequests();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://back1-f8jo.onrender.com/api/auth');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchWithdrawRequests = async () => {
    try {
      const response = await axios.get('https://back1-f8jo.onrender.com/api/auth/admin/withdraw-requests');
      const formattedRequests = response.data.map((request) => ({
        ...request,
        date: request.createdAt
          ? new Date(request.createdAt).toISOString().split('T')[0]
          : new Date().toISOString().split('T')[0], // Format date
      }));
      setWithdrawRequests(formattedRequests);
    } catch (error) {
      console.error('Error fetching withdrawal requests:', error);
    }
  };

  // Edit Withdraw Request Date
  const handleEditWithdrawRequestDate = (request) => {
    const formattedDate = request.createdAt
      ? new Date(request.createdAt).toISOString().split('T')[0]
      : new Date().toISOString().split('T')[0];
    setEditingWithdrawRequest({
      ...request,
      date: formattedDate,
    });
  };

  // Save Updated Withdraw Request Date
  const handleSaveWithdrawRequestDate = async () => {
    try {
      const selectedDate = new Date(editingWithdrawRequest.date); // Date selected from input

      // Check if the date is valid
      if (isNaN(selectedDate.getTime())) {
        alert('Invalid date format.');
        return; // Exit if the date is invalid
      }

      // Format the date as YYYY-MM-DD (only date, no time)
      const formattedDate = selectedDate.toISOString().split('T')[0];

      // Send the formatted date as the createdAt field in the PATCH request
      const response = await axios.patch(
        `https://back1-f8jo.onrender.com/api/auth/admin/withdraw-requests/${editingWithdrawRequest._id}`,
        { createdAt: formattedDate },
        { headers: { 'Content-Type': 'application/json' } } // Optional: if the server expects it
      );

      if (response.data) {
        const updatedRequest = {
          ...response.data,
          createdAt: formattedDate, // Ensure consistency in date format
        };

        // Update local state
        setWithdrawRequests((prevRequests) =>
          prevRequests.map((request) =>
            request._id === updatedRequest._id ? updatedRequest : request
          )
        );

        alert('Date de retrait mise à jour avec succès.');
        setEditingWithdrawRequest(null); // Close modal
      }
    } catch (error) {
      console.error('Error updating withdrawal request date:', error);
      console.error('Response status:', error.response?.status);
      console.error('Response data:', error.response?.data);
      alert('Erreur lors de la mise à jour de la date de retrait.');
    }
  };

  return (
    <div className="admin-dashboard">
      <LuckySpin />
      <h2>Admin Dashboard</h2>
      <Tabs>
        <TabList>
          <Tab>Withdrawal Requests</Tab>
        </TabList>

        <TabPanel>
          <h3>Withdrawal Requests</h3>
          <table className="admin-table">
            <thead>
              <tr>
                <th>User ID</th>
                <th>Amount</th>
                <th>Address</th>
                <th>Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {withdrawRequests
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((request) => (
                  <tr key={request._id}>
                    <td>{request.userId}</td>
                    <td>{request.amount}</td>
                    <td>{request.address}</td>
                    <td>{request.date}</td>
                    <td>{request.status}</td>
                    <td>
                      <button onClick={() => handleEditWithdrawRequestDate(request)}>
                        Edit Date
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </TabPanel>
      </Tabs>

      {/* Modal for editing withdrawal request date */}
      {editingWithdrawRequest && (
        <Modal
          title="Edit Withdrawal Request Date"
          onClose={() => setEditingWithdrawRequest(null)}
          onSave={handleSaveWithdrawRequestDate}
        >
          <div>
            <label>
              Date:
              <input
                type="date"
                value={editingWithdrawRequest.date}
                onChange={(e) =>
                  setEditingWithdrawRequest({
                    ...editingWithdrawRequest,
                    date: e.target.value,
                  })
                }
              />
            </label>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AdminDashboard;
