import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import InvestmentPlans from '../components/InvestmentPlans';
import Footerdashboard from '../components/footerdashboard';
import Loader from '../components/Loader'; // Import the Loader component
import '../styles/Task.css';

const Task = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state
  const plans = useSelector((state) => state.investmentPlans.plans);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      const userData = localStorage.getItem('user');

      if (!token || !userData) {
        navigate('/');
        return;
      }

      let parsedUser;
      try {
        parsedUser = JSON.parse(userData);
      } catch (err) {
        setError('Error retrieving user data.');
        setLoading(false); // Set loading to false
        return;
      }

      const userId = parsedUser?.id;
      if (!userId) {
        setError('User not found in local storage.');
        setLoading(false); // Set loading to false
        return;
      }

      try {
        const response = await axios.get(`https://back1-f8jo.onrender.com/api/auth/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setUser(response.data);
      } catch (error) {
        console.error('Error retrieving user data:', error);
        setError('Error retrieving user data.');
      } finally {
        setLoading(false); // Set loading to false at the end of the process
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleTrade = async () => {
    if (!user || user.isTradedToday) {
      alert("You have already traded today.");
      return;
    }
    if (user.balance < 20) {
      alert("Your balance must be at least $20 to trade.");
      return;
    }

    const plan = plans.find((p) => p.vipLevel === user.investmentPlan);
    const profitPercentage = parseFloat(plan.profitPercentage) / 100;
    const dayProfit = user.balance * profitPercentage;
    
    try {
      const token = localStorage.getItem('token');
      await axios.post(`https://back1-f8jo.onrender.com/api/auth/trade/${user._id}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      alert(`Trade executed successfully! Profits: $${dayProfit.toFixed(2)}`);
    } catch (error) {
      console.error('Error executing trade:', error);
      alert('Error executing trade: ' + (error.response?.data?.message || 'Unknown error'));
    }
  };

  if (loading) {
    return <Loader />; // Show loader while loading
  }

  return (
    <div className="task-container box">
      <h1>Task</h1>
      <Footerdashboard />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {user && (
        <>
          <h2>Balance: ${user.balance}</h2>
          <h3>Investment Plan: {user.investmentPlan}</h3>
          <h3 className='important'>Total Profits: ${user.totalProfits}</h3>
          <button onClick={handleTrade} disabled={user.isTradedToday}>
            {user.isTradedToday ? "Trade already executed" : "Trade"}
          </button>
          <InvestmentPlans />
        </>
      )}
    </div>
  );
};

export default Task;
