import React from 'react';
import '../styles/rules.css'; // Ensure you have a CSS file for styling

const Rules = () => {
  return (
    <div className="rules-container">
      <h2>General Information</h2>
      <ul className="rules-list">
        <li><strong>Sign-up Bonus:</strong> $3 (automatic transfer)</li>
        <li><strong>Minimum Trade:</strong> $20</li>
        <li><strong>Minimum Withdrawal:</strong> $5</li>
        <li><strong>Withdrawal Arrival Time:</strong> 0-24 hours (automatic arrival)</li>
        <li><strong>Withdrawal Fee:</strong> Fixed 1 USDT</li>
        <li><strong>Withdrawal Requirements:</strong> You can apply for withdrawal after working for 24 hours</li>
        <li><strong>First Deposit Bonus for Inviting New Users (Automatically Issued):</strong></li>
        <ul>
          <li>The first $50 deposit will be rewarded with $5</li>
          <li>The first $100 deposit will be rewarded with $10</li>
          <li>The first $300 deposit will be rewarded with $30</li>
          <li>The first $500 deposit will be rewarded with $50</li>
          <li>The first $1000 deposit will be rewarded with $98</li>
          <li>The first $3000 deposit will be rewarded with $150</li>
        </ul>
      </ul>
    </div>
  );
};

export default Rules;
