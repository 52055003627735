import React, { useState, useEffect } from 'react';
import '../styles/header.css';
import SettingsModal from './SettingsModal'; // For the settings modal
import Loader from './Loader'; // Importer le composant Loader
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State to track if the user is logged in
  const [loading, setLoading] = useState(true); // State for loading indicator
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is logged in by looking at localStorage
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    if (token && user) {
      setIsAuthenticated(true);
    }
    setLoading(false); // Stop loading after checking
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsAuthenticated(false); // Update the state
    navigate('/'); // Redirect to the login page
  };

  const handleSettings = () => {
    setIsSettingsOpen(true);
  };

  return (
    <header className="header">
      <h1>Tronsphere</h1>

      {/* Display the Loader while checking authentication */}
      {loading ? (
        <Loader />
      ) : (
        // Show buttons only if the user is logged in
        isAuthenticated && (
          <div className="header-buttons">
            <button onClick={handleSettings}>Settings</button>
            <button onClick={handleLogout}>Logout</button>
          </div>
        )
      )}

      {/* Display the settings modal */}
      {isSettingsOpen && <SettingsModal onClose={() => setIsSettingsOpen(false)} />}
    </header>
  );
};

export default Header;
