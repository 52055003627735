import React, { useContext, useState } from 'react';
import Modal from './Modal';
import SignupForm from './SignupForm';
import LoginForm from './LoginForm';
import { AuthContext } from '../App';

const AuthModal = () => {
  const { isSignupOpen, setSignupOpen, referralCode } = useContext(AuthContext);
  const [isLoginOpen, setLoginOpen] = useState(false);

  const openSignup = () => {
    setSignupOpen(true);
    setLoginOpen(false);
  };

  const openLogin = () => {
    setLoginOpen(true);
    setSignupOpen(false);
  };

  return (
    <div>
      <button onClick={openSignup}>Sign Up</button>
      <button onClick={openLogin}>Login</button>

      <Modal isOpen={isSignupOpen} onClose={() => setSignupOpen(false)}>
        <SignupForm referralCode={referralCode} />
      </Modal>

      <Modal isOpen={isLoginOpen} onClose={() => setLoginOpen(false)}>
        <LoginForm />
      </Modal>
    </div>
  );
};

export default AuthModal;