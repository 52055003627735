import React, { useState } from 'react';
import { FaUser, FaMoneyBillWave, FaArrowDown, FaUsers, FaClipboardList, FaShareAlt } from 'react-icons/fa';
import Modal from './Modal'; // Import the Modal component
import Profile from '../components/profile';
import Team from '../components/Team';
import InviteFriends from '../components/InviteFriends';
import Deposit from '../components/Deposit';
import Withdraw from '../components/Withdraw';
import Rules from '../components/rules.js';
import '../styles/ButtonContainer.css';

const ButtonContainer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: '' });

  const openModal = (title, content) => {
    setModalContent({ title, content });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="button-container box">
      <button onClick={() => openModal('Profile', <Profile />)}>
        <FaUser /> Profile
      </button>
      <button onClick={() => openModal('Deposit', <Deposit />)}>
        <FaMoneyBillWave /> Deposit
      </button>
      <button onClick={() => openModal('Withdraw', <Withdraw />)}>
        <FaArrowDown /> Withdraw
      </button>
      <button onClick={() => openModal('Team', <Team />)}>
        <FaUsers /> Team
      </button>
      <button onClick={() => openModal('Rules', <Rules />)}>
        <FaClipboardList /> Rules
      </button>
      <button onClick={() => openModal('Invite Friends', <InviteFriends />)}>
        <FaShareAlt /> Invite Friends
      </button>

      {/* Integration of the Modal with dynamic content */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <h2>{modalContent.title}</h2>
        <p>{modalContent.content}</p>
      </Modal>
    </div>
  );
};

export default ButtonContainer;
