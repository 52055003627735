import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import Modal from './Modal'; // Import the Modal component
import Profile from '../components/profile'; // Make sure the path is correct
import InviteFriends from '../components/InviteFriends';
import '../styles/Footerdashboard.css';
import accueil from '../assests/accueil.png';
import task from '../assests/tache-terminee.png';
import invit from '../assests/invitation.png';
import utl from '../assests/utilisateur.png';

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: '' });
  const navigate = useNavigate(); // Used to redirect to different pages

  const openModal = (title, content) => {
    setModalContent({ title, content });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="footer1">
        {/* Redirect to the Dashboard page */}
        <div className="footer1-item" onClick={() => navigate('/dashboard')}>
          <img src={accueil} alt="Home" />
          <span>Home</span>
        </div>

        {/* Redirect to the Task page */}
        <div className="footer1-item" onClick={() => navigate('/task')}>
          <img src={task} alt="Task" />
          <span>Task</span>
        </div>

        {/* Open the "Invite Friends" modal */}
        <div className="footer1-item" onClick={() => openModal('Invite Friends', <InviteFriends />)}>
          <img src={invit} alt="Invite Friends" />
          <span>Invite Friends</span>
        </div>

        {/* Open the "Me" (Profile) modal */}
        <div className="footer1-item" onClick={() => openModal('Profile', <Profile />)}>
          <img src={utl} alt="Me" />
          <span>Me</span>
        </div>
      </div>

      {/* Modal to display dynamic content */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <h2>{modalContent.title}</h2>
        {modalContent.content}
      </Modal>
    </>
  );
};

export default Footer;
