import React, { useState, useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react'; // Make sure you have installed qrcode.react
import '../styles/Deposit.css'; // Make sure you have a CSS file for styling

const Deposit = () => {
  const [address, setAddress] = useState('');

  useEffect(() => {
    // Retrieve the TRC20 address from local storage
    const userData = JSON.parse(localStorage.getItem('user')) || {};
    const storedAddress = userData.trc20Address;
    if (storedAddress) {
      setAddress(storedAddress);
    }
  }, []);

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(address)
      .then(() => {
        alert('Address copied to clipboard!');
      })
      .catch(err => {
        console.error('Could not copy address: ', err);
      });
  };

  return (
    <div className="deposit-container box">
      <h2>Your TRC20 Deposit Address</h2>

      {address ? (
        <div>
          <QRCodeCanvas value={address} size={128} /> {/* QR Code for the address */}
          <p> {address}</p>
          
          <button onClick={handleCopyAddress}>Copy Address</button>
        </div>
      ) : (
        <p>No TRC20 address found in local storage.</p>
      )}
    </div>
  );
};

export default Deposit;
