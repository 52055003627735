// src/components/HelpCenter.js
import React from 'react';
import '../styles/HelpCenter.css';

const HelpCenter = () => {
    const faqs = [
        {
          question: "What is the source of revenue for TronSphere?",
          answer: "TronSphere generates revenue through AI-driven trading strategies in cryptocurrency and other financial markets. The platform uses advanced algorithms to predict market movements and execute trades, earning profits from successful trades. Additionally, TronSphere charges fees on deposits, withdrawals, and trading activities."
        },
        {
          question: "How do I apply for a TronSphere business license?",
          answer: "To apply for a TronSphere business license, visit our official website and fill out the business registration form. You will need to provide necessary business details, legal documents, and agree to our terms and conditions. Once your application is submitted, it will undergo a verification process before approval."
        },
        {
          question: "What are the advantages of TronSphere's intelligent quantification system?",
          answer: "TronSphere's intelligent quantification system leverages AI and machine learning to analyze large amounts of market data in real-time. This allows for better decision-making and more accurate predictions. The system can adapt to changing market conditions and optimize investment strategies for higher returns."
        },
        {
          question: "What is TronSphere's intelligent quantitative trading system?",
          answer: "TronSphere's intelligent quantitative trading system uses artificial intelligence and big data analysis to perform high-frequency trades with minimal human intervention. The system continuously learns and refines its strategies based on historical and real-time market data, maximizing profitability while minimizing risks."
        },
        {
          question: "What is quantitative trading?",
          answer: "Quantitative trading involves the use of algorithms and mathematical models to make trading decisions. These models analyze market data to identify patterns and opportunities for profit. Quantitative trading is highly data-driven and is often used in high-frequency trading (HFT) and other automated strategies."
        },
        {
          question: "What information is available about the TronSphere company?",
          answer: "TronSphere is a leading investment platform specializing in AI-driven trading strategies. Founded by experts in both finance and artificial intelligence, TronSphere aims to provide retail and institutional investors with access to advanced quantitative trading tools. The company is committed to transparency, security, and continuous innovation."
        }
      ];

  return (
    <div className="help-center">
      <h2>Help Center</h2>
      <ul>
        {faqs.map((faq, index) => (
          <li key={index}>
            <strong>{faq.question}</strong>
            <p>{faq.answer}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HelpCenter;
