import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  plans: [
    {
      vipLevel: 'Beginner Tier',
      amountRange: '20.00 ~ 500.00',
      profitPercentage: '2.00%',
      directMembers: 0,
    },
    {
      vipLevel: 'Novice Tier',
      amountRange: '500.00 ~ 1000.00',
      profitPercentage: '2.50% ',
      directMembers: 5,
    },
    {
      vipLevel: 'Intermediate Tier',
      amountRange: '1000.00 ~ 3000.00',
      profitPercentage: '3.00%',
      directMembers: 10,
    },
    {
      vipLevel: 'Advanced Tier',
      amountRange: '3000.00 ~ 5000.00',
      profitPercentage: '3.50% ',
      directMembers: 15,
    },
    {
      vipLevel: 'Master Tier',
      amountRange: '5000.00 ~ 100000.00',
      profitPercentage: '5.00%',
      directMembers: 20,
    },
  ],
  bonus: {
    signUpBonus: '3 USDT (automatic transfer)',
    minimumTopUp: '20 USDT',
    minimumWithdrawal: '5 USDT',
    withdrawalTime: '0-24 hours (automatic arrival)',
    withdrawalFee: 'Fixed 1 USDT',
    withdrawalRequirements: 'You can apply for withdrawal after working for 24 hours',
  },
};

const investmentPlansSlice = createSlice({
  name: 'investmentPlans',
  initialState,
  reducers: {},
});

export default investmentPlansSlice.reducer;
