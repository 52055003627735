import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import Banner from '../components/banner';
import HowItWorks from '../components/HowItWorks';
import InvestmentPlans from '../components/InvestmentPlans';
import Bonus from '../components/Bonus';
import Information from '../components/Information';
import PyramidInvestment from '../components/PyramidInvestment';
import AuthModal from '../components/AuthModal';

const Home = () => {
  const navigate = useNavigate();

  // Check if the user is logged in
  useEffect(() => {
    const token = localStorage.getItem('token'); // Or use another method to check if the user is logged in
    if (token) {
      navigate('/dashboard'); // Redirect to dashboard if the user is logged in
    }
  }, [navigate]);

  return (
    <div>
      <Banner />
      <HowItWorks />
      <InvestmentPlans />
      <PyramidInvestment />
      <Bonus />
      <Information />
      <AuthModal />
    </div>
  );
};

export default Home;
