import React from 'react';
import '../styles/howItWorks.css';

const HowItWorks = () => {
  return (
    <section className="how-it-works">
      <h3>How does it work?</h3>
      <div className="steps-container">
        <div className="step">
          <h4>Step 1</h4>
          <p>Register on our platform</p>
        </div>
        <div className="step">
          <h4>Step 2</h4>
          <p>Choose an investment plan</p>
        </div>
        <div className="step">
          <h4>Step 3</h4>
          <p>Start earning profits</p>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
