import React, { useState } from 'react';
import axios from 'axios';

const SettingsModal = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Send a verification code via email
  const sendVerificationCode = async () => {
    try {
      await axios.post('https://back1-f8jo.onrender.com/api/auth/send-verification-code', { email });
      setCodeSent(true);
      alert('Verification code sent to your email.');
    } catch (error) {
      console.error('Error sending the code:', error);
      alert('Error sending the verification code.');
    }
  };

  // Change password after verifying the code
  const changePassword = async () => {
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match.');
      return;
    }

    try {
      await axios.post('https://back1-f8jo.onrender.com/api/auth/reset-password', {
        email,
        code: verificationCode,
        newPassword,
      });
      alert('Password changed successfully.');
      onClose(); // Close the modal
    } catch (error) {
      console.error('Error resetting the password:', error);
      alert('Error resetting the password.');
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Change Password</h2>

        {!codeSent ? (
          <>
            <p>Please enter your email to receive a verification code.</p>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={sendVerificationCode}>Send Code</button>
          </>
        ) : (
          <>
            <p>A code has been sent to {email}. Please enter it below:</p>
            <input
              type="text"
              placeholder="Verification Code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button onClick={changePassword}>Change Password</button>
          </>
        )}

        <button className="close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default SettingsModal;
