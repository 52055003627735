import React from 'react';
import { useSelector } from 'react-redux';
import '../styles/bonus.css';

const Bonus = () => {
  // Use Redux to retrieve bonuses from the store
  const bonus = useSelector((state) => state.investmentPlans.bonus);

  return (
    <section className="bonus-section">
      <h3>Bonus Sign-up & Top-Up</h3>
      <ul className="bonus-list">
        <li><strong>Sign-up Bonus:</strong> {bonus.signUpBonus}</li>
        <li><strong>Minimum Top-up:</strong> {bonus.minimumTopUp}</li>
        <li><strong>Minimum Withdrawal:</strong> {bonus.minimumWithdrawal}</li>
        <li><strong>Withdrawal Arrival Time:</strong> {bonus.withdrawalTime}</li>
        <li><strong>Withdrawal Fee:</strong> {bonus.withdrawalFee}</li>
        <li><strong>Withdrawal Requirements:</strong> {bonus.withdrawalRequirements}</li>
      </ul>
    </section>
  );
};

export default Bonus;
