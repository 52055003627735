// src/components/TelegramIcon.js
import React from 'react';
import telegram from '../assests/telegram.png';
import '../styles/Telegram.css'; // Importez le fichier CSS pour le style

const TelegramIcon = () => {
  return (
    <div className="telegram-icon-container">
      <a href="https://t.me/natalytronsphere" target="_blank" rel="noopener noreferrer">
        <img src={telegram} alt="Telegram" className="telegram-icon" />
      </a>
    </div>
  );
};

export default TelegramIcon;
