import React from 'react';
import { QRCodeCanvas } from 'qrcode.react'; // Import QRCodeCanvas
import '../styles/InviteFriends.css';

const InviteFriends = () => {
  // Retrieve user data from localStorage
  const userData = JSON.parse(localStorage.getItem('user')) || {};
  const referralLink = userData.referralLink || 'https://tronsphere.com/referral/' + userData.id; // Replace with the actual link

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    alert('Referral link copied to clipboard!');
  };

  const shareLink = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Join me on this platform!',
        text: 'Check out my referral link!',
        url: referralLink,
      })
      .then(() => console.log('Successfully shared'))
      .catch((error) => console.log('Error sharing', error));
    } else {
      alert('Sharing is not supported on this browser.');
    }
  };

  return (
    <div className="invite-friends-container box">
      <h2>Invite Friends</h2>
      <p>Share your referral link with friends to earn rewards!</p>
      
      <div className="qr-code">
        <QRCodeCanvas value={referralLink} size={128} />
      </div>
      
      <div className="referral-link-container">
        <p><strong>Referral Link:</strong> {referralLink}</p>
        <button onClick={copyToClipboard}>Copy Link</button>
        <button onClick={shareLink}>Share Link</button>
      </div>
    </div>
  );
};

export default InviteFriends;
