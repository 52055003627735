// src/components/Loader.js
import React from 'react';


const Loader = () => {
  return (
    <div className="loader">
      <p>Loading...</p> {/* You can replace this with a spinner or animation */}
    </div>
  );
};

export default Loader;
