import { BrowserRouter as Router, Route, Routes, Navigate, useParams, useNavigate } from 'react-router-dom';
import React, { createContext, useState } from 'react';
import axios from 'axios';
import Header from './components/header';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Task from './pages/Task';
import Admin from './pages/Admin';
import Footer from './components/footer';
import TelegramIcon from './components/TelegramIcon';
import Lila from './pages/Lila';
import Jhon from './pages/Jhon';
import Withdrawdate from './pages/withdrawdate';

export const AuthContext = createContext();

function App() {
  const [isSignupOpen, setSignupOpen] = useState(false);
  const [referralCode, setReferralCode] = useState(null);

  const verifyReferralCode = async (code) => {
    try {
      const response = await axios.get(`https://back1-f8jo.onrender.com/api/auth/referral/${code}`);
      return response.data.exists;
    } catch (error) {
      console.error('Erreur lors de la vérification du code de parrainage :', error);
      return false;
    }
  };

  return (
    <AuthContext.Provider value={{ isSignupOpen, setSignupOpen, referralCode, setReferralCode, verifyReferralCode }}>
      <Router>
        <Header />
        <TelegramIcon />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/task" element={<Task />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin11" element={<Withdrawdate />} />
          <Route path="/referral/671cefee8cd8701c80c102b6" element={<Lila />} />
          <Route path="/referral/671e4417d4c2b6481a1ff1f6" element={<Jhon />} />
          <Route path="/forgot-password" element={<Home />} />
          <Route path="/referral/:ref" element={<HomeWithSignup />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </Router>
    </AuthContext.Provider>
  );
}

const HomeWithSignup = () => {
  const { ref } = useParams();
  const { setSignupOpen, setReferralCode, verifyReferralCode } = React.useContext(AuthContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    const handleReferral = async () => {
      if (ref) {
        const isValid = await verifyReferralCode(ref);
        if (isValid) {
          setReferralCode(ref);
          setSignupOpen(true);
        }
      }
      navigate('/', { replace: true });
    };

    handleReferral();
  }, [ref, setSignupOpen, setReferralCode, verifyReferralCode, navigate]);

  return null;
};

export default App;
