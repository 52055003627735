import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/Profile.css';
import SettingsModal from './SettingsModal';

const Profile = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null); 
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(''); 
  const [activeTab, setActiveTab] = useState('assets');
  const [userHistory, setUserHistory] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      const userData = localStorage.getItem('user');

      if (!token || !userData) {
        navigate('/');
        return;
      }

      let parsedUser;
      try {
        parsedUser = JSON.parse(userData);
      } catch (err) {
        setError('Error parsing user data.');
        setLoading(false);
        return;
      }

      const userId = parsedUser?.id;
      if (!userId) {
        setError('User not found in local storage.');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`https://back1-f8jo.onrender.com/api/auth/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (error.response?.status === 503) {
          setError('The server is temporarily unavailable. Please try again later.');
        } else {
          setError('Error fetching user data.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedUserData = localStorage.getItem('user');
      if (updatedUserData) {
        setUserData(JSON.parse(updatedUserData));
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  useEffect(() => {
    if (userData) {
      setUserHistory(userData.activities || []);
    }
  }, [userData]);

  if (loading) {
    return <p>Loading data...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case 'assets':
        return (
          <div className="profile-container box">
            {userData && (
              <>
                <h1 className="important"><strong>Balance:</strong> ${userData.balance || 0}</h1>
                <h2 className="important"><strong>Total Profits:</strong> ${userData.totalProfits || 0}</h2>
                <div className="profile-info">
                  <p><strong>Username:</strong> {userData.username}</p>
                  <p><strong>Email:</strong> {userData.email}</p>
                  <p><strong>Investment Plan:</strong> {userData.investmentPlan || 'N/A'}</p>
                </div>
              </>
            )}
          </div>
        );
      case 'history':
        return (
          <div className="history-container box">
            <h2>Transaction History</h2>
            {userHistory.length > 0 ? (
              <table className="history-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Currency</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {userHistory
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .map((activity, index) => (
                      <tr key={index} className="history-item">
                        <td>{new Date(activity.date).toISOString().split('T')[0]}</td>
                        <td>{activity.type}</td>
                        <td>${activity.amount}</td>
                        <td>{activity.currency || 'USDT'}</td>
                        <td>{activity.status}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : (
              <p>No history available.</p>
            )}
          </div>
        );
      case 'settings':
        return (
          <div className="settings-container box">
            <h2>Account Settings</h2>
            <SettingsModal />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="profile-page">
      <div className="tabs">
        <button onClick={() => setActiveTab('assets')} className={activeTab === 'assets' ? 'active' : ''}>Assets</button>
        <button onClick={() => setActiveTab('history')} className={activeTab === 'history' ? 'active' : ''}>History</button>
        <button onClick={() => setActiveTab('settings')} className={activeTab === 'settings' ? 'active' : ''}>Settings</button>
      </div>
      <div className="tab-content">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default Profile;
