// src/components/ForgotPassword.js
import React, { useState } from 'react';
import axios from 'axios';
import ResetPassword from './ResetPassword';

const ForgotPassword = ({ onCodeSent }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [codeSent, setCodeSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://back1-f8jo.onrender.com/api/auth/send-verification-code', { email });
      setMessage(response.data.message);
      setErrorMessage('');
      setCodeSent(true); // Indicate that the code has been sent
    } catch (error) {
      setErrorMessage(error.response ? error.response.data.message : 'Error sending the code.');
      setMessage('');
    }
  };

  return (
    <div>
      <h2>Forgot Password</h2>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {message && <p style={{ color: 'green' }}>{message}</p>}

      {!codeSent ? (
        <form onSubmit={handleSubmit}>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit">Send Verification Code</button>
        </form>
      ) : (
        <ResetPassword email={email} onClose={onCodeSent} />
      )}
    </div>
  );
};

export default ForgotPassword;
