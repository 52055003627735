import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Pour la redirection
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Modal from '../components/Modal';
import Loader from '../components/Loader'; // Import du composant Loader si nécessaire
import '../styles/Admin.css';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [withdrawRequests, setWithdrawRequests] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [userDetails, setUserDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState(null);
  const [loading, setLoading] = useState(true); // État de chargement
  const [User, setUser] = useState(null); // Utilisateur connecté

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      const userData = localStorage.getItem('user');
      
      if (!token || !userData) {
        navigate('/'); // Redirige vers la page d'accueil si pas de token ou user data
      } else {
        const User = JSON.parse(userData);
        setUser(User);

        // Vérification de l'email de l'utilisateur connecté
        if (User.email !== 'fouad.maouchi.96@gmail.com') {
          alert("Accès non autorisé.");
          navigate('/'); // Redirige vers la page d'accueil si l'email n'est pas correct
        } else {
          fetchUsers(); // Récupérer les utilisateurs après vérification
          fetchWithdrawRequests(); // Récupérer les demandes de retrait
        }
      }
      setLoading(false); // Fin du chargement
    };

    fetchUserData();
  }, [navigate]);

  // Récupérer les utilisateurs
  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://sharkhold.com/api/auth');
      setUsers(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des utilisateurs:', error);
    }
  };

  // Récupérer les demandes de retrait
  const fetchWithdrawRequests = async () => {
    try {
      const response = await axios.get('https://sharkhold.com/api/auth/admin/withdraw-requests');
      setWithdrawRequests(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des retraits:', error);
    }
  };

  // Filtrer les utilisateurs par ID ou email
  const filteredUsers = users.filter(user =>
    user._id.includes(searchTerm) || user.email.includes(searchTerm)
  );

  const handleAcceptWithdraw = async (withdrawId, userId, amount, address) => {
    try {
      await axios.patch(`https://sharkhold.com/api/auth/admin/withdraw-requests/${withdrawId}`, { status: 'accepted' });
      await axios.patch(`https://sharkhold.com/api/auth/users/${userId}/activities`, {
        type: 'withdraw',
        amount: amount,
        address: address,
        status: 'approved'
      });

      alert('Demande de retrait acceptée.');
      fetchWithdrawRequests(); // Actualiser les retraits après acceptation
    } catch (error) {
      console.error('Erreur lors de l\'acceptation de la demande de retrait:', error);
      alert('Une erreur est survenue lors de l\'acceptation de la demande de retrait.');
    }
  };

  const handleDeclineWithdraw = async (withdrawId, userId, amount, address) => {
    try {
      await axios.patch(`https://sharkhold.com/api/auth/admin/withdraw-requests/${withdrawId}`, { status: 'rejected' });
      await axios.patch(`https://sharkhold.com/api/auth/users/${userId}/activities`, {
        type: 'withdraw',
        amount: amount,
        address: address,
        status: 'declined'
      });

      alert('Demande de retrait refusée.');
      fetchWithdrawRequests(); // Actualiser les retraits après refus
    } catch (error) {
      console.error('Erreur lors du refus de la demande de retrait:', error);
      alert('Une erreur est survenue lors du refus de la demande de retrait.');
    }
  };

  const handleViewDetails = (user) => {
    setUserDetails(user);
  };

  const handleEditUser = (user) => {
    setEditedUser(user);
    setIsEditing(true);
  };

  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`https://sharkhold.com/api/auth/${userId}`);
      alert('Utilisateur supprimé avec succès.');
      fetchUsers(); // Rafraîchir la liste des utilisateurs après suppression
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'utilisateur:', error);
      alert('Une erreur est survenue lors de la suppression de l\'utilisateur.');
    }
  };

  const handleSaveEdit = async (updatedUser) => {
    try {
      await axios.patch(`https://sharkhold.com/api/auth/${updatedUser._id}`, updatedUser);
      alert('Informations de l\'utilisateur mises à jour.');
      fetchUsers(); // Rafraîchir la liste des utilisateurs après modification
      setIsEditing(false);
      setEditedUser(null);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'utilisateur:', error);
      alert('Une erreur est survenue lors de la mise à jour des informations de l\'utilisateur.');
    }
  };

  if (loading) {
    return <Loader />; // Affiche un loader pendant que les données sont chargées
  }

  return (
    <div className="admin-dashboard">
      <h2>Tableau de Bord Admin</h2>
      <Tabs>
        <TabList>
          <Tab>Utilisateurs</Tab>
          <Tab>Dépôts</Tab>
          <Tab>Demandes de Retrait</Tab>
        </TabList>

        {/* Onglet 1: Gestion des utilisateurs */}
        <TabPanel>
          <h3>Utilisateurs</h3>
          <input
            type="text"
            placeholder="Rechercher par ID ou Email"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <table className="admin-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nom d'utilisateur</th>
                <th>Email</th>
                <th>Solde</th>
                <th>Plan d'investissement</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map(user => (
                <tr key={user._id}>
                  <td>{user._id}</td>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{user.balance}</td>
                  <td>{user.investmentPlan}</td>
                  <td>
                    <button onClick={() => handleViewDetails(user)}>Détails</button>
                    <button onClick={() => handleEditUser(user)}>Modifier</button>
                    <button onClick={() => handleDeleteUser(user._id)}>Supprimer</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TabPanel>

        {/* Onglet 2: Liste des dépôts */}
        <TabPanel>
          <h3>Dépôts des utilisateurs</h3>
          <table className="admin-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Email</th>
                <th>Montant</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {/* Ajoutez ici le contenu des dépôts si nécessaire */}
            </tbody>
          </table>
        </TabPanel>

        {/* Onglet 3: Demandes de retrait */}
        <TabPanel>
          <h3>Demandes de Retrait</h3>
          <table className="admin-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Email</th>
                <th>Montant</th>
                <th>Adresse</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {withdrawRequests.map(request => (
                <tr key={request._id}>
                  <td>{request.userId}</td>
                  <td>{request.email}</td>
                  <td>{request.amount}</td>
                  <td>{request.address}</td>
                  <td>{new Date(request.createdAt).toLocaleDateString()}</td>
                  <td>
                    {request.status === 'pending' ? (
                      <>
                        <button onClick={() => handleAcceptWithdraw(request._id, request.userId, request.amount, request.address)}>
                          Accepter
                        </button>
                        <button onClick={() => handleDeclineWithdraw(request._id, request.userId, request.amount, request.address)}>
                          Refuser
                        </button>
                      </>
                    ) : (
                      <span>{request.status}</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TabPanel>
      </Tabs>

      {/* Modal pour les détails de l'utilisateur */}
      {userDetails && (
        <Modal user={userDetails} onClose={() => setUserDetails(null)} />
      )}

      {/* Modal pour la modification de l'utilisateur */}
      {isEditing && (
        <Modal user={editedUser} onClose={() => setIsEditing(false)} onSave={handleSaveEdit} />
      )}
    </div>
  );
};

export default AdminDashboard;
