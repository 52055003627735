import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import pour la redirection
import { useLocation } from 'react-router-dom';
import '../styles/signupForm.css';

// Liste des codes de pays pour le sélecteur
const countryCodes = [
  { code: '+1', name: 'États-Unis' },
  { code: '+1', name: 'Canada' },
  { code: '+1242', name: 'Bahamas' },
  { code: '+1246', name: 'Barbade' },
  { code: '+1264', name: 'Anguilla' },
  { code: '+1268', name: 'Antigua-et-Barbuda' },
  { code: '+1284', name: 'Îles Vierges britanniques' },
  { code: '+1340', name: 'Îles Vierges américaines' },
  { code: '+1345', name: 'Îles Caïmans' },
  { code: '+1441', name: 'Bermudes' },
  { code: '+1473', name: 'Grenade' },
  { code: '+1649', name: 'Îles Turques-et-Caïques' },
  { code: '+1664', name: 'Montserrat' },
  { code: '+1671', name: 'Guam' },
  { code: '+1758', name: 'Sainte-Lucie' },
  { code: '+1767', name: 'Dominique' },
  { code: '+1784', name: 'Saint-Vincent-et-les-Grenadines' },
  { code: '+1809', name: 'République dominicaine' },
  { code: '+1868', name: 'Trinité-et-Tobago' },
  { code: '+1869', name: 'Saint-Kitts-et-Nevis' },
  { code: '+1876', name: 'Jamaïque' },
  { code: '+20', name: 'Égypte' },
  { code: '+212', name: 'Maroc' },
  { code: '+213', name: 'Algérie' },
  { code: '+216', name: 'Tunisie' },
  { code: '+218', name: 'Libye' },
  { code: '+220', name: 'Gambie' },
  { code: '+221', name: 'Sénégal' },
  { code: '+222', name: 'Mauritanie' },
  { code: '+223', name: 'Mali' },
  { code: '+224', name: 'Guinée' },
  { code: '+225', name: "Côte d'Ivoire" },
  { code: '+226', name: 'Burkina Faso' },
  { code: '+227', name: 'Niger' },
  { code: '+228', name: 'Togo' },
  { code: '+229', name: 'Bénin' },
  { code: '+230', name: 'Maurice' },
  { code: '+231', name: 'Liberia' },
  { code: '+232', name: 'Sierra Leone' },
  { code: '+233', name: 'Ghana' },
  { code: '+234', name: 'Nigeria' },
  { code: '+235', name: 'Tchad' },
  { code: '+236', name: 'République centrafricaine' },
  { code: '+237', name: 'Cameroun' },
  { code: '+238', name: 'Cap-Vert' },
  { code: '+239', name: 'Sao Tomé-et-Principe' },
  { code: '+240', name: 'Guinée équatoriale' },
  { code: '+241', name: 'Gabon' },
  { code: '+242', name: 'République du Congo' },
  { code: '+243', name: 'République démocratique du Congo' },
  { code: '+244', name: 'Angola' },
  { code: '+245', name: 'Guinée-Bissau' },
  { code: '+246', name: 'Diego Garcia' },
  { code: '+247', name: 'Ascension' },
  { code: '+248', name: 'Seychelles' },
  { code: '+249', name: 'Soudan' },
  { code: '+250', name: 'Rwanda' },
  { code: '+251', name: 'Éthiopie' },
  { code: '+252', name: 'Somalie' },
  { code: '+253', name: 'Djibouti' },
  { code: '+254', name: 'Kenya' },
  { code: '+255', name: 'Tanzanie' },
  { code: '+256', name: 'Ouganda' },
  { code: '+257', name: 'Burundi' },
  { code: '+258', name: 'Mozambique' },
  { code: '+260', name: 'Zambie' },
  { code: '+261', name: 'Madagascar' },
  { code: '+262', name: 'Réunion' },
  { code: '+263', name: 'Zimbabwe' },
  { code: '+264', name: 'Namibie' },
  { code: '+265', name: 'Malawi' },
  { code: '+266', name: 'Lesotho' },
  { code: '+267', name: 'Botswana' },
  { code: '+268', name: 'Eswatini' },
  { code: '+269', name: 'Comores' },
  { code: '+27', name: 'Afrique du Sud' },
  { code: '+290', name: 'Sainte-Hélène' },
  { code: '+291', name: 'Érythrée' },
  { code: '+297', name: 'Aruba' },
  { code: '+298', name: 'Îles Féroé' },
  { code: '+299', name: 'Groenland' },
  { code: '+30', name: 'Grèce' },
  { code: '+31', name: 'Pays-Bas' },
  { code: '+32', name: 'Belgique' },
  { code: '+33', name: 'France' },
  { code: '+34', name: 'Espagne' },
  { code: '+350', name: 'Gibraltar' },
  { code: '+351', name: 'Portugal' },
  { code: '+352', name: 'Luxembourg' },
  { code: '+353', name: 'Irlande' },
  { code: '+354', name: 'Islande' },
  { code: '+355', name: 'Albanie' },
  { code: '+356', name: 'Malte' },
  { code: '+357', name: 'Chypre' },
  { code: '+358', name: 'Finlande' },
  { code: '+359', name: 'Bulgarie' },
  { code: '+36', name: 'Hongrie' },
  { code: '+370', name: 'Lituanie' },
  { code: '+371', name: 'Lettonie' },
  { code: '+372', name: 'Estonie' },
  { code: '+373', name: 'Moldavie' },
  { code: '+374', name: 'Arménie' },
  { code: '+375', name: 'Biélorussie' },
  { code: '+376', name: 'Andorre' },
  { code: '+377', name: 'Monaco' },
  { code: '+378', name: 'Saint-Marin' },
  { code: '+379', name: 'Vatican' },
  { code: '+380', name: 'Ukraine' },
  { code: '+381', name: 'Serbie' },
  { code: '+382', name: 'Monténégro' },
  { code: '+383', name: 'Kosovo' },
  { code: '+385', name: 'Croatie' },
  { code: '+386', name: 'Slovénie' },
  { code: '+387', name: 'Bosnie-Herzégovine' },
  { code: '+389', name: 'Macédoine du Nord' },
  { code: '+39', name: 'Italie' },
  { code: '+40', name: 'Roumanie' },
  { code: '+41', name: 'Suisse' },
  { code: '+420', name: 'République tchèque' },
  { code: '+421', name: 'Slovaquie' },
  { code: '+423', name: 'Liechtenstein' },
  { code: '+43', name: 'Autriche' },
  { code: '+44', name: 'Royaume-Uni' },
  { code: '+45', name: 'Danemark' },
  { code: '+46', name: 'Suède' },
  { code: '+47', name: 'Norvège' },
  { code: '+48', name: 'Pologne' },
  { code: '+49', name: 'Allemagne' },
  { code: '+500', name: 'Îles Malouines' },
  { code: '+501', name: 'Belize' },
  { code: '+502', name: 'Guatemala' },
  { code: '+503', name: 'El Salvador' },
  { code: '+504', name: 'Honduras' },
  { code: '+505', name: 'Nicaragua' },
  { code: '+506', name: 'Costa Rica' },
  { code: '+507', name: 'Panama' },
  { code: '+508', name: 'Saint-Pierre-et-Miquelon' },
  { code: '+509', name: 'Haïti' },
  { code: '+51', name: 'Pérou' },
  { code: '+52', name: 'Mexique' },
  { code: '+53', name: 'Cuba' },
  { code: '+54', name: 'Argentine' },
  { code: '+55', name: 'Brésil' },
  { code: '+56', name: 'Chili' },
  { code: '+57', name: 'Colombie' },
  { code: '+58', name: 'Venezuela' },
  { code: '+590', name: 'Guadeloupe' },
  { code: '+591', name: 'Bolivie' },
  { code: '+592', name: 'Guyana' },
  { code: '+593', name: 'Équateur' },
  { code: '+594', name: 'Guyane française' },
  { code: '+595', name: 'Paraguay' },
  { code: '+596', name: 'Martinique' },
  { code: '+597', name: 'Suriname' },
  { code: '+598', name: 'Uruguay' },
  { code: '+599', name: 'Curaçao' },
  { code: '+60', name: 'Malaisie' },
  { code: '+61', name: 'Australie' },
  { code: '+62', name: 'Indonésie' },
  { code: '+63', name: 'Philippines' },
  { code: '+64', name: 'Nouvelle-Zélande' },
  { code: '+65', name: 'Singapour' },
  { code: '+66', name: 'Thaïlande' },
  { code: '+670', name: 'Timor oriental' },
  { code: '+672', name: 'Territoire antarctique australien' },
  { code: '+673', name: 'Brunei' },
  { code: '+674', name: 'Nauru' },
  { code: '+675', name: 'Papouasie-Nouvelle-Guinée' },
  { code: '+676', name: 'Tonga' },
  { code: '+677', name: 'Îles Salomon' },
  { code: '+678', name: 'Vanuatu' },
  { code: '+679', name: 'Fidji' },
  { code: '+680', name: 'Palaos' },
  { code: '+681', name: 'Wallis-et-Futuna' },
  { code: '+682', name: 'Îles Cook' },
  { code: '+683', name: 'Niue' },
  { code: '+685', name: 'Samoa' },
  { code: '+686', name: 'Kiribati' },
  { code: '+687', name: 'Nouvelle-Calédonie' },
  { code: '+688', name: 'Tuvalu' },
  { code: '+689', name: 'Polynésie française' },
  { code: '+690', name: 'Tokelau' },
  { code: '+691', name: 'États fédérés de Micronésie' },
  { code: '+692', name: 'Îles Marshall' },
  { code: '+7', name: 'Russie' },
  { code: '+7', name: 'Kazakhstan' },
  { code: '+81', name: 'Japon' },
  { code: '+82', name: 'Corée du Sud' },
  { code: '+84', name: 'Vietnam' },
  { code: '+850', name: 'Corée du Nord' },
  { code: '+852', name: 'Hong Kong' },
  { code: '+853', name: 'Macao' },
  { code: '+855', name: 'Cambodge' },
  { code: '+856', name: 'Laos' },
  { code: '+86', name: 'Chine' },
  { code: '+880', name: 'Bangladesh' },
  { code: '+886', name: 'Taïwan' },
  { code: '+90', name: 'Turquie' },
  { code: '+91', name: 'Inde' },
  { code: '+92', name: 'Pakistan' },
  { code: '+93', name: 'Afghanistan' },
  { code: '+94', name: 'Sri Lanka' },
  { code: '+95', name: 'Myanmar' },
  { code: '+960', name: 'Maldives' },
  { code: '+961', name: 'Liban' },
  { code: '+962', name: 'Jordanie' },
  { code: '+963', name: 'Syrie' },
  { code: '+964', name: 'Irak' },
  { code: '+965', name: 'Koweït' },
  { code: '+966', name: 'Arabie saoudite' },
  { code: '+967', name: 'Yémen' },
  { code: '+968', name: 'Oman' },
  { code: '+970', name: 'Palestine' },
  { code: '+971', name: 'Émirats arabes unis' },
  { code: '+972', name: 'Israël' },
  { code: '+973', name: 'Bahreïn' },
  { code: '+974', name: 'Qatar' },
  { code: '+975', name: 'Bhoutan' },
  { code: '+976', name: 'Mongolie' },
  { code: '+977', name: 'Népal' },
  { code: '+98', name: 'Iran' },
  { code: '+992', name: 'Tadjikistan' },
  { code: '+993', name: 'Turkménistan' },
  { code: '+994', name: 'Azerbaïdjan' },
  { code: '+995', name: 'Géorgie' },
  { code: '+996', name: 'Kirghizistan' },
  { code: '+998', name: 'Ouzbékistan' },
  { code: '+1242', name: 'Bahamas' },
  { code: '+1246', name: 'Barbade' },
  { code: '+1264', name: 'Anguilla' },
  { code: '+1268', name: 'Antigua-et-Barbuda' },
  { code: '+1284', name: 'Îles Vierges britanniques' },
  { code: '+1340', name: 'Îles Vierges américaines' },
  { code: '+1345', name: 'Îles Caïmans' },
  { code: '+1441', name: 'Bermudes' },
  { code: '+1473', name: 'Grenade' },
  { code: '+1649', name: 'Îles Turques-et-Caïques' },
  { code: '+1664', name: 'Montserrat' },
  { code: '+1670', name: 'Îles Mariannes du Nord' },
  { code: '+1671', name: 'Guam' },
  { code: '+1684', name: 'Samoa américaines' },
  { code: '+1721', name: 'Sint Maarten' },
  { code: '+1758', name: 'Sainte-Lucie' },
  { code: '+1767', name: 'Dominique' },
  { code: '+1784', name: 'Saint-Vincent-et-les Grenadines' },
  { code: '+1868', name: 'Trinité-et-Tobago' },
  { code: '+1869', name: 'Saint-Kitts-et-Nevis' },
  { code: '+1876', name: 'Jamaïque' },
  { code: '+1939', name: 'Porto Rico' },
];



const SignupForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extraire le code de parrainage de l'URL
  const getReferralCodeFromURL = () => {
    const pathname = location.pathname;
    const regex = /\/referral\/([a-zA-Z0-9]+)/;
    const match = pathname.match(regex);
    return match ? match[1] : '';
  };

  const referralCode = getReferralCodeFromURL();

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phoneNumber: '',
    countryCode: '+1',
    password: '',
    confirmPassword: '',
    referredBy: referralCode,
    verificationCode: '',
  });

  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [showVerificationInput, setShowVerificationInput] = useState(false);

  const [message, setMessage] = useState('');
  const [isReferralValid, setIsReferralValid] = useState(true); // État pour la validité du code

  // Vérifier le code de parrainage
  const verifyReferralCode = async (code) => {
    if (!code) return false;
    try {
      // Exemple de vérification par API
      const response = await axios.get(`https://back1-f8jo.onrender.com/api/auth/referral/${code}`);
      return response.data.exists;
    } catch (error) {
      console.error('Error verifying referral code:', error);
      return false;
    }
  };

  useEffect(() => {
    const validateReferral = async () => {
      if (referralCode) {
        const isValid = await verifyReferralCode(referralCode);
        setIsReferralValid(isValid);
        if (!isValid) {
          setMessage('Invalid referCode  but you can Signup.');
        }
      }
    };

    validateReferral();
  }, [referralCode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Empêcher la modification du champ referredBy si un code de parrainage est présent dans l'URL
    if (name === 'referredBy' && referralCode) {
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSendVerificationCode = async () => {
    try {
      const response = await axios.post('https://back1-f8jo.onrender.com/api/auth/send-verification-code', {
        email: formData.email,
      });
      if (response.status === 200) {
        setMessage('Verification code sent to your email.');
        setShowVerificationInput(true);
      }
    } catch (error) {
      setMessage(`Error sending the code : ${error.response ? error.response.data.message : "Connection error"}`);
    }
  };

  const handleVerifyCode = async () => {
    try {
        const response = await axios.post('https://back1-f8jo.onrender.com/api/auth/verify-code', {
            email: formData.email,
            code: formData.verificationCode,
        });
        if (response.status === 200) {
            setMessage('Email successfully verified.');
            setIsEmailVerified(true);
        }
    } catch (error) {
        setMessage(`Verification error: ${error.response ? error.response.data.message : "Connection error"}`);
    }
};

const handleSubmit = async (e) => {
  e.preventDefault();
  if (!isEmailVerified) {
      setMessage("Please verify your email before continuing.");
      return;
  }
  if (formData.password !== formData.confirmPassword) {
      setMessage("The passwords do not match.");
      return;
  }

  // Include the valid referral code in the submission
  const submitData = {
      ...formData,
      referredBy: isReferralValid ? referralCode : '',
  };

  try {
      const response = await axios.post('https://back1-f8jo.onrender.com/api/auth/signup', submitData);
      if (response.status === 201) {
          setMessage('Registration successful! You will be redirected soon.');
          navigate('/dashboard'); // Redirect to a success page
      }
  } catch (error) {
      setMessage(`Registration error: ${error.response ? error.response.data.message : "Connection error"}`);
  }
};


  return (
    <form className="signup-form" onSubmit={handleSubmit}>
      <h2>Sign Up</h2>
      {message && <p>{message}</p>}
  
      {/* Username and Email Fields */}
      {!isEmailVerified && (
        <>
          <div>
            <label>Username:</label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
  
          {/* Verification Code Input */}
          {!showVerificationInput ? (
            <div>
              <button type="button" onClick={handleSendVerificationCode}>
                Send Verification Code
              </button>
            </div>
          ) : (
            <div>
              <label>Verification Code:</label>
              <input
                type="text"
                name="verificationCode"
                value={formData.verificationCode}
                onChange={handleChange}
              />
              <button type="button" onClick={handleVerifyCode}>
                Verify
              </button>
            </div>
          )}
        </>
      )}
  
      {/* Phone Number and Password Fields */}
      {isEmailVerified && (
        <>
          <div className="phone-input">
            <label>Phone Number:</label>
            <select
              name="countryCode"
              value={formData.countryCode}
              onChange={handleChange}
            >
              {countryCodes.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name} ({country.code})
                </option>
              ))}
            </select>
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Confirm Password:</label>
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Referred By:</label>
            <input
              type="text"
              name="referredBy"
              value={formData.referredBy}
              readOnly
            />
          </div>
          <button type="submit">Sign Up</button>
        </>
      )}
    </form>
  );

};
export default SignupForm;
