import React from 'react';
import photo from '../assests/3dback.jpg';
import '../styles/VideoAnimation.css';
const VideoAnimation = () => {
  return (
    <div className="video-animation">
      
      <img src={photo} alt='toro' />
    </div>
  );
};

export default VideoAnimation;