import React from 'react';
import '../styles/banner.css';
import AuthModal from './AuthModal';

const Banner = () => {
  return (
    <section className="banner">
      <h1>Invest safely on a long-term platform</h1>
      <p>Join our platform and start investing today.</p>
      <AuthModal />
    </section>
  );
};

export default Banner;
