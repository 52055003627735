// src/components/ResetPassword.js
import React, { useState } from 'react';
import axios from 'axios';

const ResetPassword = ({ email, onClose }) => {
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://back1-f8jo.onrender.com/api/auth/reset-password', { email, code, newPassword });
      setMessage(response.data.message);
      setErrorMessage('');
      onClose(); // Close the modal after resetting
    } catch (error) {
      setErrorMessage(error.response ? error.response.data.message : 'Error resetting password.');
      setMessage('');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Reset Password</h2>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {message && <p style={{ color: 'green' }}>{message}</p>}
      
      <label>Verification Code</label>
      <input
        type="text"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        required
      />
      
      <label>New Password</label>
      <input
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        required
      />

      <button type="submit">Reset Password</button>
    </form>
  );
};

export default ResetPassword;
