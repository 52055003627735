import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VideoAnimation from '../components/VideoAnimation';
import ButtonContainer from '../components/ButtonContainer';
import CryptoPricesNews from '../components/CryptoPricesNews';
import HelpCenter from '../components/HelpCenter';
import Bonus from '../components/Bonus';
import InvestmentPlans from '../components/InvestmentPlans';
import PyramidInvestment from '../components/PyramidInvestment';
import Loader from '../components/Loader'; // Import the Loader component
import '../styles/Dashboard.css';
import Footerdashboard from '../components/footerdashboard';
import RouletteIcon from '../components/rouletteicon';

const Dashboard = () => {
  const navigate = useNavigate();
  const [User, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      const userData = localStorage.getItem('user');

      if (!token || !userData) {
        navigate('/');
      } else {
        setUser(JSON.parse(userData)); // Retrieve user information
        
      }
      
      setLoading(false); // Set loading to false after fetching
    };

    fetchUserData();
  }, [navigate]);

  // Respond to each update in localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      const updatedUserData = localStorage.getItem('user');
      if (updatedUserData) {
        setUser(JSON.parse(updatedUserData));
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  
  if (loading) {
    return <Loader />; // Show loader while loading
  }

  return (
    <div className="dashboard">
      <RouletteIcon />
      <VideoAnimation />
      
      {User && (
        <div className="balance-info">
          <h2>Welcome, {User.username}</h2>
          
        </div>
      )}
      <ButtonContainer />
      <Bonus />
      <PyramidInvestment />
      <InvestmentPlans />
      <CryptoPricesNews />
      <HelpCenter />
      <Footerdashboard />
    </div>
  );
};

export default Dashboard;
