import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for HTTP requests
import '../styles/loginForm.css';
import Modal from './Modal';
import ForgotPassword from './ForgotPassword';

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  // Function to refresh localStorage
  const refreshLocalStorage = () => {
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('token');

    if (user && token) {
      // Refresh the localStorage by updating it with the same data
      localStorage.setItem('user', user);
      localStorage.setItem('token', token);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send the login request to the API
      const response = await axios.post('https://back1-f8jo.onrender.com/api/auth/login', formData);

      // Store all user information in localStorage, including _id
      localStorage.setItem('token', response.data.token);  // Store the JWT token
      localStorage.setItem('user', JSON.stringify(response.data.user));  // Store all user data

      // Redirect the user to the dashboard
      navigate('/dashboard');
    } catch (error) {
      if (error.response && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
    }
  };

  // UseEffect to refresh localStorage every 20 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      refreshLocalStorage();
    }, 20000); // Refresh every 20 seconds

    // Cleanup the interval when component unmounts
    return () => clearInterval(interval);
  }, []); // Empty dependency array means this runs once when the component mounts

  return (
    <>
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>Login</h2>

        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

        <label>Email</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label>Password</label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />

        <button type="submit">Login</button>
        <p>
          <button type="button" onClick={() => setModalOpen(true)}>Forgot password?</button>
        </p>
      </form>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <ForgotPassword />
      </Modal>
    </>
  );
};

export default LoginForm;
