import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/Withdraw.css'; // Ensure you have the appropriate CSS file

const Withdraw = () => {
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [withdrawType, setWithdrawType] = useState('USDT'); // New state for withdrawal type
  const [errorMessage, setErrorMessage] = useState('');
  const [userBalance, setUserBalance] = useState(0);
  const [trxBalance, setTrxBalance] = useState(0); // New state for TRX balance
  const [usdtToTrxRate, setUsdtToTrxRate] = useState(1); // Conversion rate for USDT to TRX
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // For redirecting if necessary

  useEffect(() => {
    // Fetch user data and conversion rate on component mount
    const fetchUserDataAndRate = async () => {
      const token = localStorage.getItem('token');
      const userData = localStorage.getItem('user');

      if (!token || !userData) {
        navigate('/'); // Redirect if user is not logged in
        return;
      }

      let parsedUser;
      try {
        parsedUser = JSON.parse(userData); // Parse user data
      } catch (err) {
        setError('Error fetching user data.');
        setLoading(false); // End loading
        return;
      }

      const userId = parsedUser?.id;
      if (!userId) {
        setError('User not found in local storage.');
        setLoading(false); // End loading
        return;
      }

      try {
        // Fetch user information and USDT to TRX conversion rate
        const [userResponse, rateResponse] = await Promise.all([
          axios.get(`https://back1-f8jo.onrender.com/api/auth/${userId}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get('https://api.coingecko.com/api/v3/simple/price?ids=tether,tron&vs_currencies=usd'), // Fetch TRX rate from CoinGecko
        ]);

        // Extract balance and conversion rate
        setUserBalance(userResponse.data.balance);
        const trxRate = rateResponse.data.tron.usd / rateResponse.data.tether.usd; // USDT to TRX rate
        setUsdtToTrxRate(trxRate);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data.');
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchUserDataAndRate();
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'address') {
      setAddress(value);
    } else if (name === 'amount') {
      setAmount(value);
    }
    setErrorMessage(''); // Reset errors when the user modifies the form
  };

  const handleWithdrawTypeChange = (e) => {
    setWithdrawType(e.target.value);
    setErrorMessage(''); // Reset errors on type change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const withdrawalAmount = parseFloat(amount);
    let finalAmount = withdrawalAmount;

    if (withdrawType === 'TRX') {
      // Convert USDT amount to TRX
      finalAmount = withdrawalAmount * usdtToTrxRate;
    }

    // Check: Minimum amount and amount less than or equal to user balance
    if (finalAmount < 5) {
      setErrorMessage('The minimum withdrawal amount is 5 USDT or its equivalent in TRX.');
      return;
    }
    if (withdrawType === 'USDT' && withdrawalAmount > userBalance) {
      setErrorMessage(`The withdrawal amount cannot exceed your USDT balance of ${userBalance} USDT.`);
      return;
    }
    if (withdrawType === 'TRX' && finalAmount > userBalance) {
      setErrorMessage(`The equivalent TRX withdrawal amount cannot exceed your balance of ${userBalance} USDT.`);
      return;
    }

    // Perform the withdrawal (interaction with the API)
    try {
      const userId = JSON.parse(localStorage.getItem('user')).id; // Ensure the user's ID is available
      const response = await fetch('https://back1-f8jo.onrender.com/api/auth/user/withdraw', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, address, amount: finalAmount, type: withdrawType }),
      });

      const result = await response.json();
      if (response.ok) {
        setAddress('');
        setAmount('');
        alert('Withdrawal request submitted successfully.');
      } else {
        setErrorMessage(result.message);
      }
    } catch (error) {
      console.error('Error submitting withdrawal:', error);
      setErrorMessage('Error submitting the withdrawal request.');
    }
  };

  if (loading) {
    return <p>Loading user data...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="withdraw-container box">
      <h2>Withdraw Funds</h2>

      {/* Withdrawal form */}
      <form onSubmit={handleSubmit}>
        <label htmlFor="address">USDT/TRX Address:</label>
        <input
          type="text"
          id="address"
          name="address"
          value={address}
          onChange={handleInputChange}
          required
        />

        <label htmlFor="amount">Enter Amount (USDT/TRX):</label>
        <input
          type="number"
          id="amount"
          name="amount"
          value={amount}
          onChange={handleInputChange}
          required
        />

        <label htmlFor="withdrawType">Withdrawal Type:</label>
        <select id="withdrawType" value={withdrawType} onChange={handleWithdrawTypeChange}>
          <option value="USDT">USDT (TRC-20)</option>
          <option value="TRX">TRX</option>
        </select>

        {withdrawType === 'TRX' && (
          <p>Converted Amount: {amount ? (amount * usdtToTrxRate).toFixed(2) : 0} Usdt</p>
        )}

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <button type="submit">Withdraw</button>
        <p>Minimum withdrawal is 5 USDT or equivalent in TRX.</p>
        <p>Withdrawal takes from 1 hour to 24 hours.</p>
      </form>
    </div>
  );
};

export default Withdraw;
