import React, { useEffect, useState } from 'react';
import axios from 'axios'; // For HTTP requests
import '../styles/Team.css'; // Make sure to have a CSS file for styling

const Team = () => {
  const [teamData, setTeamData] = useState({
    totalUsers: 0,
    activeUsers: 0,
    usersByLevel: { level1: 0, level2: 0, level3: 0 },
    totalCommission: 0,
    users: { level1Users: [], level2Users: [], level3Users: [] }, // Store users by level
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('general'); // State to manage tabs

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));

        if (!user || !user.id) {
          setError("User not found in local storage.");
          setLoading(false);
          return;
        }

        const response = await axios.get(`https://back1-f8jo.onrender.com/api/auth/team/${user.id}`);

        setTeamData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching team data:', error);
        setError('Error fetching team data.');
        setLoading(false);
      }
    };

    fetchTeamData();
  }, []);

  if (loading) {
    return <p>Loading team data...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  const { totalUsers, activeUsers, usersByLevel, totalCommission, users } = teamData;

  // Function to render users by level
  const renderUsersList = (usersList) => {
    if (usersList.length === 0) {
      return <p>No users found.</p>;
    }
    return (
      <ul>
        {usersList.map((user, index) => (
          <li key={index}>{user.name} (ID: {user.id})</li> // Make sure to use the correct fields
        ))}
      </ul>
    );
  };

  return (
    <div className="team-container box">
      {/* Tab bar */}
      <div className="tabs">
        <button
          className={activeTab === 'general' ? 'active' : ''}
          onClick={() => setActiveTab('general')}
        >
          General
        </button>
        <button
          className={activeTab === 'level1' ? 'active' : ''}
          onClick={() => setActiveTab('level1')}
        >
          Level 1
        </button>
        <button
          className={activeTab === 'level2' ? 'active' : ''}
          onClick={() => setActiveTab('level2')}
        >
          Level 2
        </button>
        <button 
          className={activeTab === 'level3' ? 'active' : ''}
          onClick={() => setActiveTab('level3')}
        >
          Level 3
        </button>
      </div>

      {/* Content based on the selected tab */}
      <div className="tab-content">
        {activeTab === 'general' && (
          <div className="team-info">
            <h2 className="important"><strong>Valid Direct Users:</strong> {activeUsers}</h2>
            <p><strong>Total Users:</strong> {totalUsers}</p>
            <p><strong>Users at Level 1:</strong> {usersByLevel.level1}</p>
            <p><strong>Users at Level 2:</strong> {usersByLevel.level2}</p>
            <p><strong>Users at Level 3:</strong> {usersByLevel.level3}</p>
            <h1 className="important"><strong>Total Commission:</strong> ${totalCommission}</h1>
          </div>
        )}

        {activeTab === 'level1' && (
          <div className="level-users">
            <h2>Users at Level 1</h2>
            {renderUsersList(users.level1Users)}
          </div>
        )}

        {activeTab === 'level2' && (
          <div className="level-users">
            <h2>Users at Level 2</h2>
            {renderUsersList(users.level2Users)}
          </div>
        )}

        {activeTab === 'level3' && (
          <div className="level-users">
            <h2>Users at Level 3</h2>
            {renderUsersList(users.level3Users)}
          </div>
        )}
      </div>
    </div>
  );
};

export default Team;
